<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Edit Product" />
        <div>
          <md-button :to="{ name: 'products' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateUser">
          <md-field :class="getValidationClass('title')">
            <label>Product Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Title is required</span
            >
            <span class="md-error" v-if="!$v.form.title.minLength"
              >Title too Short</span
            >
          </md-field>

          <md-field :class="getValidationClass('description')">
            <label>Product Description</label>
            <md-textarea v-model="form.description" required></md-textarea>
            <span class="md-error" v-if="!$v.form.description.required"
              >Description is required</span
            >
            <span class="md-error" v-if="!$v.form.description.minLength"
              >Description too Short</span
            >
          </md-field>

          <md-field :class="getValidationClass('title')">
            <label>Product Brand</label>
            <md-input v-model="form.brand"></md-input>
          </md-field>

          <md-field v-if="metadata" :class="getValidationClass('category_id')">
            <label for="category">Category</label>
            <md-select v-model="form.category_id" name="category" id="category">
              <md-option value="" disabled>Select Category</md-option>
              <md-option
                v-for="(category, id) in metadata.categories"
                :key="id"
                :value="id"
                >{{ category }}</md-option
              >
            </md-select>
            <span class="md-error" v-if="!$v.form.category_id.required"
              >Category is required</span
            >
          </md-field>

          <div class="feature-attributes">
            <div class="feature-header">
              <div class="wrapper">
                <div class="attr">
                  <h4>Features</h4>
                </div>
                <div class="val">
                  <h4>Value</h4>
                </div>
              </div>
            </div>
            <div
              class="wrapper"
              v-for="(attr, i) in form.selectedAttributes"
              :key="i"
            >
              <div class="attr">
                <md-field v-if="metadata">
                  <select
                    :name="`selectedAttributes[${i}][key]`"
                    id="category"
                    v-model="attr.key"
                    class="custom-input"
                  >
                    <option value="" disabled>Select Attribute</option>
                    <option
                      v-for="(attribute, id) in metadata.attributes"
                      :key="id"
                      :value="id"
                    >
                      {{ attribute }}
                    </option>
                  </select>
                </md-field>
              </div>

              <div class="val">
                <md-field>
                  <input
                    class="custom-input"
                    v-model="attr.value"
                    :name="`selectedAttributes[${i}][value]`"
                    id="value"
                  />
                </md-field>
              </div>
              <div>
                <md-button
                  v-if="i != 0"
                  class="md-primary btn-sm"
                  @click="removeFeature(i)"
                >
                  Remove
                </md-button>
              </div>
            </div>

            <md-button
              class="md-primary btn-sm btn-outlined"
              @click="addFeature"
            >
              Add Feature
            </md-button>
          </div>

          <md-field v-if="metadata">
            <label for="tags">Tags</label>
            <md-select
              v-model="form.selectedTags"
              name="tags"
              id="tags"
              multiple
            >
              <md-option value="" disabled>Select Tags</md-option>
              <md-option
                v-for="(tag, id) in metadata.tags"
                :key="tag"
                :value="id"
                >{{ tag }}</md-option
              >
            </md-select>
          </md-field>

          <md-field :class="getValidationClass('price')">
            <label>Product Price</label>
            <md-input v-model="form.price" required type="number"></md-input>
            <span class="md-error" v-if="!$v.form.price.required"
              >Price is required</span
            >
          </md-field>

          <md-field>
            <label>Product Old Price</label>
            <md-input v-model="form.old_price" type="number"></md-input>
          </md-field>

          <md-field>
            <label>Quantity</label>
            <md-input v-model="form.quantity" type="number" required></md-input>
            <span class="md-error" v-if="!$v.form.quantity.required"
              >Quantity is required</span
            >
          </md-field>

          <md-field>
            <label>Product Commission</label>
            <md-input v-model="form.product_comm" type="number"></md-input>
          </md-field>

          <md-field>
            <label for="pro_com_status">Product Commission Status</label>
            <md-select
              v-model="form.pro_com_status"
              name="pro_com_status"
              id="pro_com_status"
            >
              <md-option value="" disabled>Select Status</md-option>
              <md-option value="live">Live</md-option>
              <md-option value="draft">Draft</md-option>
            </md-select>
          </md-field>

          <md-field>
            <label>Quantity</label>
            <md-input v-model="form.quantity" type="number" required></md-input>
            <span class="md-error" v-if="!$v.form.quantity.required"
              >Quantity is required</span
            >
          </md-field>

          <div class="media">
            <div class="display" v-for="image in images" :key="image">
              <div class="close" @click="deleteImage(image)">
                <i class="fa fa-times"></i>
              </div>
              <img
                :src="image"
                onerror="this.src='https://via.placeholder.com/150x150?text=No%20Image'"
                alt="Display Image"
              />
            </div>
          </div>

          <md-field>
            <label>Preview</label>
            <input type="file" @change="handleFiles" name="images[]" multiple />
          </md-field>
          <br />

          <mdc-button :loading="loading">Update Product </mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("product");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      title: "",
      description: "",
      category_id: null,
      selectedAttributes: [
        {
          key: "",
          value: "",
        },
      ],
      selectedTags: [],
      images: [],
    },
    loading: false,
    metadata: null,
    images: [],
  }),
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(10),
      },
      category_id: {
        required,
      },
      price: {
        required,
      },
      quantity: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      "updateProduct",
      "getMetaData",
      "getProduct",
      "removeProductImage",
    ]),
    async validateUser() {
      console.log(this.$v.form);
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let data = { ...this.form };
        data.selectedAttributes = JSON.stringify(data.selectedAttributes);
        let tags = data.selectedTags.map((id) => {
          return { id };
        });
        data.selectedTags = JSON.stringify(tags);
        try {
          this.loading = true;
          await this.updateProduct(data);
          this.toast("success", "Product Updated");
          this.$router.push({ name: "products" });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          //   this.parseError(err);
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    addFeature() {
      this.form.selectedAttributes.push({
        key: "",
        value: "",
      });
    },
    removeFeature(id) {
      this.form.selectedAttributes.splice(id, 1);
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    async getData() {
      this.fetching = true;
      const res = await this.getMetaData();
      this.fetching = false;
      this.metadata = res.data;
    },
    async fetchProduct() {
      const { product } = await this.getProduct(this.id);
      let newProduct = {
        id: product.id,
        title: product.title,
        description: product.description,
        category_id: product.category_id,
        price: product.price,
        old_price: product.old_price,
        quantity: product.quantity,
        quantity: product.quantity,
        brand: product.brand,
        approval_status: product.approval_status,
        images: [],
      };
      this.images = product.images;
      newProduct.selectedTags = product.tags.map((tag) => tag.id);
      newProduct.selectedAttributes = product.attributes.map((attribute) => {
        return {
          key: attribute.id,
          value: attribute.pivot.value,
        };
      });

      this.form = newProduct;
    },
    handleFiles(e) {
      let files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        this.form.images.push(files[i]);
      }
    },
    async deleteImage(filename_path) {
      try {
        this.loading = true;
        const conf = confirm("Are you sure?");
        const f_arr = filename_path.split("/");
        const filename = f_arr[f_arr.length - 1];
        if (conf) {
          await this.removeProductImage({ filename, model: this.form.id });
          console.log(this.form.images);
          this.images = this.images.filter((img) => img !== filename_path);
          this.toast("success", "Image Removed");
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.toast("error", "Unable to delete image");
      }
    },
  },
  mounted() {
    this.getData();
    this.fetchProduct();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }

    @media (max-width: 420px) {
      flex-direction: column;
      align-items: start;
      gap: 0px;
      .val {
        flex: 1;
      }

      .attr {
        width: 100%;
      }
    }
  }
}
.media {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .display {
    height: 120px;
    width: 120px;
    background: #ccc;
    position: relative;
    border-radius: 5px;

    .close {
      position: absolute;
      top: 8px;
      right: 8px;
      color: #fff;
      height: 20px;
      width: 20px;
      display: grid;
      place-content: center;
      border-radius: 200px;
      background: #f72121;
      cursor: pointer;
      font-size: 12px;
    }
    img {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
